<template>
  <GlobalDialog
    class="square flex flex-col items-center bg-white !p-4 !max-w-[30%] lg:max-w-[33%] !max-h-[90vh] rounded-md"
  >
    <h1 v-text="localize('title')" />
    <p class="mt-2" v-text="localize('subtitle')" />
    <div class="mt-2 mb-1 w-full border overflow-auto avv-tab-background">
      <div class="flex items-center">
        <input
          type="checkbox"
          class="avv-button avv-checkbox focus:!bg-transparent"
          :class="allClass()"
          @click="allClick()"
        />
        <span v-text="localize('all')" />
      </div>
      <div
        v-for="[templateName, group] in documentGroups"
        :key="templateName"
        class="mb-1 ml-8"
      >
        <div class="flex items-center">
          <input
            type="checkbox"
            class="avv-button avv-checkbox focus:!bg-transparent"
            :class="groupClass(group)"
            @click="groupClick(group)"
          />
          <span v-text="templateName" />
        </div>
        <div
          v-for="[documentId, documentName] in group"
          :key="documentId"
          class="flex items-center ml-12"
        >
          <input
            type="checkbox"
            class="avv-button avv-checkbox focus:!bg-transparent"
            :class="checkboxClass(documentId)"
            @click="checkboxClick(documentId)"
          />
          <span v-text="documentName" /><span
            class="text-gray-500 text-sm ml-1"
            v-text="`(${documentId})`"
          />
        </div>
      </div>
    </div>
    <div class="flex items-center w-full">
      <input
        type="checkbox"
        class="avv-button avv-checkbox focus:!bg-transparent"
        :class="{ checked: acceptChanges }"
        @click="acceptChanges = !acceptChanges"
      />
      <span v-text="localize('accept_changes')" />
    </div>
    <p class="text-sm text-gray-600 my-2" v-text="localize('notice')" />
    <div class="grid grid-cols-2 gap-2 w-full">
      <button
        class="avv-button w-full secondary"
        @click="actionCancel"
        v-text="localize('cancel')"
      />
      <button
        class="avv-button w-full"
        @click="actionContinue"
        v-text="localize('continue')"
      />
    </div>
  </GlobalDialog>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import GlobalDialog from '../dialogs/GlobalDialog.vue'

export default defineComponent({
  name: 'DocumentUpdateDialog',
  components: { GlobalDialog },
  props: {
    bindings: {
      type: Object,
      required: true
    },
    values: {
      type: Array,
      default: []
    }
  },
  emits: ['callback'],
  setup(props, ctx) {
    const documentIds = ref(new Set(props.values))
    const documentGroups = ref(props.bindings)
    const documentAllIds = ref(
      props.bindings
        .map(([, group]: any) => group.map(([documentId]: any) => documentId))
        .flat()
    )
    const acceptChanges = ref(false)

    const localize = (key: string) =>
      window.localizeText(`datasheets.document_update_dialog.${key}`)

    const actionContinue = () =>
      ctx.emit('callback', Array.from(documentIds.value), acceptChanges.value)
    const actionCancel = () => ctx.emit('callback', null)

    const allClass = () => {
      return {
        checked: documentIds.value.size > 0,
        indeterminate: documentIds.value.size != documentAllIds.value.length
      }
    }

    const allClick = () => {
      if (documentIds.value.size > 0) {
        documentIds.value = new Set()
      } else {
        documentIds.value = new Set(documentAllIds.value)
      }
    }

    const groupClass = (group: Array<string>) => {
      const checkedCount = group.filter(([documentId]) =>
        documentIds.value.has(documentId)
      ).length

      return {
        checked: checkedCount > 0,
        indeterminate: checkedCount != group.length
      }
    }

    const groupClick = (group: Array<string>) => {
      if (group.some(([documentId]) => documentIds.value.has(documentId))) {
        for (const [documentId] of group) {
          documentIds.value.delete(documentId)
        }
      } else {
        for (const [documentId] of group) {
          documentIds.value.add(documentId)
        }
      }
    }

    const checkboxClass = (documentId: string) => {
      return {
        checked: documentIds.value.has(documentId)
      }
    }

    const checkboxClick = (documentId: string) => {
      if (documentIds.value.has(documentId)) {
        documentIds.value.delete(documentId)
      } else {
        documentIds.value.add(documentId)
      }
    }

    return {
      localize,
      actionContinue,
      actionCancel,
      documentIds,
      documentGroups,
      allClass,
      allClick,
      groupClick,
      groupClass,
      checkboxClick,
      checkboxClass,
      acceptChanges
    }
  }
})
</script>
